import React from 'react';
import tw, { styled } from 'twin.macro';
import { Link } from 'react-scroll'; 

const Container = styled(Link)`
  background-color: #f3f9e7;
  ${tw`w-full p-6 cursor-pointer`}
  width: 100vw;
  min-height: 400px;
  max-width: 100%;
`;

const Picture = styled.picture`
  width: 100%;
  height: auto;
  max-width: 100%;

  img {
    max-width: 100%;
    height: auto;
  }
`;
export default function OnlyImage({ data, page }) {
  let finalData = data?.schema_json;
  let content = {
    ...finalData,
  };

  return ( 
    <Container data-href={content.url} to={content?.url?.replace('#', '')}>
      <Picture>
        <source srcSet={content.img_src_desktop} media="(min-width: 768px)" />
        <source srcSet={content.img_src_mob} media="(max-width: 767px)" />
        <img src={content.img_src} />
      </Picture>
    </Container>
  );
}
 